#login-page{
    background-image: url(bg.jpeg);
    background-repeat: no-repeat;
    /* background-position: center 110px; */
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: rgba(0, 0, 0, 0.5); */
    background-color: transparent;
}

#login-page .login-form {
    max-width: 400px;
    width: 400px;
    /* margin: 15% auto; */
    /* position: absolute; */
    /* right: 12%; */
    background-color: #fff;
    padding: 25px;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 3px;
}
#login-page .login-form-forgot {
    float: right;
}
#login-page .login-form-button {
    width: 100%;
}
#login-page input{
    box-shadow: inset 0 0 0 1000px #fff!important;
}