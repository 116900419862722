.pay-type-item{
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;
    //border: 1px solid #f1f1f1;
    // padding: 5px;
    // padding-left: 6px;
    // padding-right: 6px;
    // border-radius: 3px;

    cursor: pointer;
    // margin-right: 10px;
    // margin-bottom: 10px;
    // padding: 2px;
    padding: 5px;
}
.pay-type-item>.bt{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
 
    font-size: 14px;
    border: 1px solid #f1f1f1;
    border-radius: 3px;
    padding: 3px;
}
// .pay-type-item:first{
//     // margin-left: 0px;
// }
.pay-type-item .bt:hover{
    color: #222;
    border: 1px solid @primary-color;
    background-color: @primary-color;
}
.pay-type-item-desc{
    font-size: 8px;
    color: #ccc;
}
.pay-type-item:hover .pay-type-item-desc{
    color: #666;
}
.pay-type-item .anticon{
    font-size: 25px;
    margin-bottom: 5px;
}
.coupon-name{
    background-color: @primary-color;
    color: #fff;
    padding: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.pay-detail-right{
    width: 200px;
    max-width: 200px;
    // border: 1px solid #222;
    text-align: right;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
}

.text-overflow{
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
}
.coupon-card{
    display: flex;
    border-left: 3px dashed @primary-color;

    flex-direction:row;
}
.coupon-card-left{
    // width: 20%;
    height: 60px;
    background-color: @primary-color;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 3;
   
    

}
.coupon-card-left>.amount{
    color: #fff;
}
.coupon-card-right{
    flex: 5;
    height: 60px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid @primary-color;
    padding: 3px;
 
    
}

.coupon-card-type{
    background-color: @primary-color;
    padding: 5;
    font-size: 12px;
    border-radius: 20px;
    padding-left: 8px;
    padding-right: 8px;
}
.res-item{
    display: flex;

}

.res-item-left{
    flex: 2;
}
.res-item-right{
    flex: 3;
    text-align: right;
}
@primary-color: #E7C77B;