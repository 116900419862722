#ba-layout{
    position: relative;
    width: 100%;
    height: 100%;
}
#ba-layout .ant-layout-header{
  // line-height: 45px;
  display: flex;
  // justify-items: center;
  // align-items: center;
  padding: 0;
  
  
}
#ba-layout .trigger {
    font-size: 18px;
    // line-height: 45px;
    cursor: pointer;
    transition: color 0.3s;
    margin-left: 10px;
    // margin-top: 3px;
  }
  
  #ba-layout .trigger:hover {
    color: #1890ff;
  }
  
  #ba-layout .logo {
    height: 32px;
    /* background: rgba(255, 255, 255, 0.2); */
    margin: 16px;
    /* text-align: center; */
    line-height: 32px;
    overflow: hidden;
    color: #fff;
  }
  
  .site-layout .site-layout-background {
    background: #fff;
  
  }

  .site-layout {
    border-left: 1px solid rgba(0,0,0,0.1);
  }

  .site-layout .site-layout-content{
    margin:  10px 10px;
    padding: 15px;
    /* height: 100%; */
    /* min-height: 500px; */
  }
  #ba-layout .layout-menu-sider{
      /* border-right: 1px solid rgba(0,0,0,0.1); */
      /* background-color: #33374b; */
      overflow-y: auto;
      overflow-x: hidden;
  }
  .site-layout .layout-breadcrumb{
      // padding: 15px;
      background-color: #fff;
      // border: 1px solid rgba(0,0,0,0.1);
      // border-left: 0px;
      // border-right: 0px;
      /* border-bottom: 0px; */
      margin-left: 10px;

  }
  .ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    /* background-color: #32b9a5; */
    background-color: rgba(255, 255, 255, 0.2);
    border-right: 3px solid @primary-color;
   
  }
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background-color: rgba(255, 255, 255, 0.2);
    border-right: 3px solid @primary-color;
  }
  .ant-menu-dark .ant-menu-item-selected > a, .ant-menu-dark .ant-menu-item-selected > a:hover{
color: @primary-color;
  }
  .ant-menu-dark .ant-menu-item-selected .anticon + span {
    color: @primary-color;
}
.ant-menu-dark .ant-menu-item-selected .anticon {
  color: @primary-color;
}
.ant-menu-dark .ant-menu-item:hover, .ant-menu-dark .ant-menu-item-active, .ant-menu-dark .ant-menu-submenu-active, .ant-menu-dark .ant-menu-submenu-open, .ant-menu-dark .ant-menu-submenu-selected, .ant-menu-dark .ant-menu-submenu-title:hover {
  /* color: #32b9a5; */
  background-color: transparent;
}
.ant-table{
  color: #222;
}

a,.ant-btn-link{
  color: #1890ff,
}
.disabled{
  pointer-events: none;
  opacity: 0.5;
}
.menu-item-a{
  width: 100;
  height: 50;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.menu-item-a>.anticon{
  font-size: 30px;
  margin-bottom: 5px;
}

.menu-item{
  margin-bottom: 15px;
  border-left: 2px solid #000;
  padding-top: 5px;
  padding-bottom: 3px;
}
.menu-item-active{
  border-left: 2px solid @primary-color;
  // background-color: hsla(0,0%,100%,.2);
  color: @primary-color;
}
.menu-item-active>a{
  color: @primary-color;
}
.amount-num-item{
  text-align: center;
  background-color: #fff;
  padding:15px;
  color:#000;
  font-size: 16px;
  // font-weight: bold;
  cursor: pointer;
  
}

.amount-num-item:hover{
  background-color: #ccc;
}

@primary-color: #E7C77B;