@import '~antd/dist/antd.less';

html,body,#root{
  height: 100%;
  width: 100%;
  font-family: '微软雅黑';
  color: #000;
}
/* 滚动条优化 start */
::-webkit-scrollbar{
width:5px;
height:5px;
}
::-webkit-scrollbar-track{
background: #f6f6f6;
border-radius:2px;
}
::-webkit-scrollbar-thumb{
background: #cdcdcd;
border-radius:2px;
}
::-webkit-scrollbar-thumb:hover{
background: #747474;
}
::-webkit-scrollbar-corner {
background: #f6f6f6;
}
.query-form .ant-form-item-control {
  display: -ms-flexbox;
  display: flex; 
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.ant-btn-primary{
  color: #222;
}
.ant-btn-primary:hover{
  color: #666;
}
html,body{
  // background-color: transparent;
}

.ant-layout{
  // background-color: transparent;
}
@primary-color: #E7C77B;